<template>
  <div>
    <span class="text-h4 success--text"
      >Группы<v-btn
        @click="create_dialog = true"
        style="float: right"
        color="success"
        outlined
        v-if="$store.getters.profile.user_access_level == 'training_officer'"
        >Новая группа</v-btn
      ></span
    >
    <v-container fluid>
      <v-row>
        <v-col
          sm="12"
          md="6"
          lg="4"
          xl="4"
          cols="12"
          v-for="group in groups"
          v-bind:key="group.id"
        >
          <ticket
            :group="group"
            :teacher_name="
              group.teacher.user.first_name + ' ' + group.teacher.user.last_name
            "
            class="mt-3"
          ></ticket>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="create_dialog" max-width="540px">
      <v-card>
        <v-card-title
          >Создать группу <v-spacer></v-spacer>
          <v-icon @click="create_dialog = false"
            >mdi-close</v-icon
          ></v-card-title
        >
        <v-card-text>
          <v-form @submit.stop.prevent="Save">
            <v-row>
              <v-col>
                <v-text-field
                  label="Название группы"
                  placeholder="FSD 3/20"
                  v-model="group_form.number"
                  :error-messages="GroupNameErrors"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  :items="courses"
                  label="Курс"
                  item-text="name"
                  item-value="id"
                  v-model="group_form.direction"
                  @change="CourseChange"
                  :error-messages="GroupCourseErrors"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="group_form.teacher"
                  :disabled="x_loading"
                  :items="teachers"
                  :error-messages="GroupTeacherErrors"
                  v-show="group_form.direction != null"
                  chips
                  dense
                  color="success"
                  label="Выберите учителя"
                  item-text="first_name"
                  item-value="id"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      dense
                      color="success"
                      class="mb-2"
                      @click="data.select"
                    >
                      <v-avatar color="white" left>
                        <v-img :src="api + data.item.user.image"></v-img>
                      </v-avatar>
                      {{ data.item.user.first_name }}
                      {{ data.item.user.last_name }}
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <img :src="api + data.item.user.image" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.user.first_name +
                            ' ' +
                            data.item.user.last_name
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="group_form.students"
                  :disabled="x_loading"
                  :items="students"
                  v-show="group_form.direction != null"
                  chips
                  dense
                  :error-messages="GroupStudentsErrors"
                  color="success"
                  label="Выбрать студентов"
                  item-text="first_name"
                  item-value="id"
                  multiple
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      close
                      :input-value="data.selected"
                      dense
                      color="success"
                      class="mb-2"
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      <v-avatar color="white" left>
                        <v-img :src="api + data.item.user.image"></v-img>
                      </v-avatar>
                      {{ data.item.user.first_name }}
                      {{ data.item.user.last_name }}
                    </v-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <img :src="api + data.item.user.image" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.user.first_name +
                            ' ' +
                            data.item.user.last_name
                          "
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <div class="text-secondary mb-2">По будням</div>
                <v-item-group v-model="lesson_form.week" multiple>
                  <v-row>
                    <v-item
                      v-for="week in weekdays"
                      v-bind:key="week"
                      v-slot="{ active, toggle }"
                    >
                      <v-col v-if="week != 'вс'" cols="2">
                        <v-card
                          :color="active ? 'success' : ''"
                          class="d-flex align-center"
                          @click="toggle"
                          rounded
                          outlined
                        >
                          <v-scroll-y-transition>
                            <div
                              class="flex-grow-1 text-center"
                              :class="active ? 'white--text' : ''"
                            >
                              {{ week }}
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-col>
                    </v-item>
                  </v-row>
                </v-item-group>
              </v-col>
              <v-col cols="6">
                <v-menu
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="group_form.open_date"
                      label="Дата начала"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      :error-messages="GroupStartDateErrors"
                      v-on="on"
                      color="success"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    v-model="group_form.open_date"
                    color="success"
                    locale="ru-RU"
                    @input="start_date_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="lesson_form.room"
                  label="Kомната"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="lesson_form.start_time"
                  label="Начальное время"
                  :items="time"
                  @blur="TimeChange"
                  :error-messages="LessonStartErrors"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="lesson_form.end_time"
                  label="Время окончания"
                  :error-messages="LessonEndErrors"
                  :items="time"
                  @blur="TimeChange"
                ></v-select>
              </v-col>
            </v-row>
            <v-btn type="submit" color="success">Сохранить</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      absolute
      right
      top
      color="green"
      elevation="6"
      multi-line
      tile
      class="snackbar"
      transition="slide-x-reverse-transition"
    >
      Группа успешно создана
    </v-snackbar>
  </div>
</template>

<script>
const Ticket = () => import('@/components/widgets/Ticket')
import { GROUP_GET, GROUP_ADD } from "@/store/actions/group";
import { COURSE_GET, LESSONS_POST } from "@/store/actions/other";
import { TEACHER_GET } from "@/store/actions/teacher";
import { STUDENT_GET, STUDENT_PUT } from "@/store/actions/student";
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      groups: [],
      create_dialog: false,
      start_date_menu: false,
      snackbar: false,
      courses: [],
      teachers: [],
      people: [],
      students: [],
      students2: [],
      end: false,
      start: false,
      weekdays: [],
      group_form: {
        number: "",
        direction: null,
        teacher: null,
        students: [],
        open_date: null,
      },
      end: "",
      start: "",
      lesson_form: {
        end_time: "",
        start_time: "",
        week: [],
        times: 80,
        room: "",
        group: "",
      },
      time: [
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
      ],
      api: process.env.VUE_APP_API_URL.slice(0, -1),
    };
  },
  computed: {
    GroupNameErrors() {
      const errors = [];
      if (!this.$v.group_form.number.$dirty) return errors;
      !this.$v.group_form.number.required &&
        errors.push("Требуется название группы");
      return errors;
    },
    GroupCourseErrors() {
      const errors = [];
      if (!this.$v.group_form.direction.$dirty) return errors;
      !this.$v.group_form.direction.required &&
        errors.push("Курс обязателен");
      return errors;
    },
    GroupTeacherErrors() {
      const errors = [];
      if (!this.$v.group_form.teacher.$dirty) return errors;
      !this.$v.group_form.teacher.required &&
        errors.push("Требуется учитель");
      return errors;
    },
    GroupStudentsErrors() {
      const errors = [];
      if (!this.$v.group_form.students.$dirty) return errors;
      !this.$v.group_form.students.required &&
        errors.push("Требуются студенты");
      return errors;
    },
    GroupStartDateErrors() {
      const errors = [];
      if (!this.$v.group_form.open_date.$dirty) return errors;
      !this.$v.group_form.open_date.required &&
        errors.push("Дата начала обязательна");
      return errors;
    },
    LessonEndErrors() {
      const errors = [];
      if (!this.$v.lesson_form.end_time.$dirty) return errors;
      !this.$v.lesson_form.end_time.required &&
        errors.push("Укажите время окончания");
      return errors;
    },
    LessonStartErrors() {
      const errors = [];
      if (!this.$v.lesson_form.start_time.$dirty) return errors;
      !this.$v.lesson_form.start_time.required &&
        errors.push("Требуется время начала");
      return errors;
    },
    LessonRoomErrors() {
      const errors = [];
      if (!this.$v.lesson_form.room.$dirty) return errors;
      !this.$v.lesson_form.room.required && errors.push("Требуется номер");
      return errors;
    },
  },
  validations() {
    return {
      group_form: {
        number: { required },
        direction: { required },
        teacher: { required },
        students: { required },
        open_date: { required },
      },
      lesson_form: {
        end_time: { required },
        start_time: { required },
        week: { required },
        room: { required },
      },
    };
  },
  components: {
    ticket: Ticket,
  },

  methods: {
    remove(item) {
      const index = this.group_form.students.indexOf(item.id);
      if (index >= 0) this.group_form.students.splice(index, 1);
    },
    async LoadGroups() {
      let resp = await this.$dispatch(GROUP_GET);
      if (resp.success) {
        if (typeof resp.result.length != "undefined") {
          this.groups = resp.result;
        } else {
          this.groups = [resp.result];
        }
      } else {
        alert(resp);
      }
    },
    async LoadCourses() {
      let resp = await this.$dispatch(COURSE_GET);
      if (resp.success) {
        this.courses = resp.result;
      } else {
        alert(resp);
      }
    },
    async LoadTeachers() {
      let resp = await this.$dispatch(TEACHER_GET);
      if (resp.success) {
        this.teachers = resp.result;
        this.people = resp.result;
      } else {
        alert(resp);
      }
    },
    async LoadStudents() {
      let resp = await this.$dispatch(STUDENT_GET, "?is_started=false");
      if (resp.success) {
        this.students = resp.result;
        this.students2 = resp.result;
      } else {
        alert(resp);
      }
    },
    GetWeekDays() {
      moment.locale("Ru");
      this.weekdays = moment.weekdaysShort();
    },
    CourseChange() {
      let teachers = [];
      let students = [];
      for (let t in this.people) {
        if (this.people[t].direction.id == this.group_form.direction) {
          teachers.push(this.people[t]);
        }
      }
      for (let s in this.students2) {
        if (this.students2[s].direction.id == this.group_form.direction) {
          students.push(this.students2[s]);
        }
      }
      this.teachers = teachers;
      this.students = students;
    },
    TimeChange() {
      let start = this.group_form.start_time;
      let end = this.group_form.end_time;
      if (start !== "") {
        this.time.indexOf(start);
      }
    },
    async Save() {
      this.$v.group_form.$touch();
      this.$v.lesson_form.$touch();
      if (!this.$v.group_form.$invalid && !this.$v.lesson_form.$invalid) {
        let resp = await this.$dispatch(GROUP_ADD, this.group_form);
        if (resp.success) {
          this.lesson_form.start_time =
            this.group_form.open_date + " " + this.lesson_form.start_time;
          this.lesson_form.end_time =
            this.group_form.open_date + " " + this.lesson_form.end_time;
          this.lesson_form.group = resp.result.id;
          let resp2 = await this.$dispatch(LESSONS_POST, this.lesson_form);
          if (resp2.success) {
            for (let s in this.group_form.students) {
              await this.$dispatch(STUDENT_PUT, {
                is_started: true,
                id: this.group_form.students[s],
              });
            }
            this.create_dialog = false;
            this.snackbar = true;
            this.LoadGroups();
            this.LoadCourses();
            this.LoadTeachers();
            this.LoadStudents();
            this.GetWeekDays();
          } else {
            console.log(resp2);
            alert(resp2);
          }
        } else {
          console.log(resp);
          alert(resp);
        }
      }
    },
  },
  created() {
    this.LoadGroups();
    this.LoadCourses();
    this.LoadTeachers();
    this.LoadStudents();
    this.GetWeekDays();
  },
};
</script>

<style>
</style>